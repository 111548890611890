import Chat from 'twitch-chat-emotes';
import FastAverageColor from 'fast-average-color';

const fac = new FastAverageColor();

let channels = ['moonmoon'];
const query_vars = {};
const query_parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
	query_vars[key] = value;
});
if (query_vars.channels) {
	channels = query_vars.channels.split(',');
}

const ChatInstance = new Chat({
	channels,
	duplicateEmoteLimit: 1,
	maximumEmoteLimit: 3,
	duplicateEmoteLimit_pleb: 0,
	maximumEmoteLimit_pleb: 1,
	shouldTrackSubs: true
})

const emoteSize = 64;
const emoteSpeed = 1;

const pendingEmoteArray = [];

ChatInstance.on("emotes", (e) => {

	for (let index = 0; index < e.emotes.length; index++) 
	{
		let varianceSize = 400
		let xVariance = varianceSize/2 - Math.random() * varianceSize;
		let yVariance = varianceSize/2 - Math.random() * varianceSize;
		const output = {
			velocity: emoteSpeed/2 + Math.random() * emoteSpeed,
			position: getSpawnPosition(xVariance, yVariance),
			emotes: [],
			firstVote: false
		};
		
		output.emotes.push(e.emotes[index]);
		
								 //calc_parabola_vertex(startX, startY, halfX, halfY, endX, endY);
		output.parabolaModifiers = calc_parabola_vertex(output.position.x, output.position.y, boothX + 450, boothY + 200, fullWidth*0.86 + xVariance, fullHeight + yVariance)
		
		output.height = emoteSize;
		output.width = emoteSize;
		 
		output.lowestY = output.position.y
		 
		pendingEmoteArray.push(output);
	}
})

ChatInstance.on("subtext", (e) => {
	
	const subObj = {
		life: 0,
		content: e
	};
	
	subMessages.push(subObj);
	
	if(subMessages.length > 7)
	{
		subMessages.shift()
	}
})

const canvas = document.createElement('canvas');
const ctx = canvas.getContext('2d');
ctx.imageSmoothingEnabled = false;

const easeInOutSine = (currentIteration, startValue, changeInValue, totalIterations) => {
	return changeInValue / 2 * (1 - Math.cos(Math.PI * currentIteration / totalIterations)) + startValue;
}

const easeInOutSinePos = (currentIteration, startValue, changeInValue, totalIterations) => {
	return changeInValue / 2 * (1 - Math.abs(Math.cos(Math.PI * currentIteration / totalIterations))) + startValue;
}

const getSpawnPosition = (xVariance, yVariance) => {
	
	let x = -100 + xVariance
	let y = fullHeight + yVariance

	return {x, y};
}

let lecternWidth = 612 * 1.3;
let lecternHeight = 612 * 1.3;
let lecternX = 1250;
let lecternY = 760;

let lennyWidth = 394 * 1.15;
let lennyHeight = 633 * 1.15;
let lennyX = 1400;
let lennyY = 490;

let smugHandXOffset = 125;
let smugHandYOffset = -5;

let boothWidth = 1000 * 0.79;
let boothHeight = 1000 * 0.79;
let boothX = 400;
let boothY = 400;

let ballotWidth = 700 * 0.6;
let ballotHeight = 700 * 0.6;
let ballotX = 50;
let ballotY = 50;

let megaWidth = 175;
let megaHeight = 128;

let steamW = 40;
let steamH = 40;

let fullWidth = window.innerWidth;
let fullHeight = window.innerHeight;

let halfx = window.innerWidth / 2;
let halfy = window.innerHeight / 2;

let redFaceBuffer = 0;
let redFace = 49;

let lennyTalk = 0;
let lennyTalkRotation = 0;
let lennyTalkRotationBuffer = 0;
let lennyArmRotation = 0;
let lennyArmRotatioBuffer = 0;
let lennyTalkAdjust = 0;
let lennyTalkTimer = 0;
let lennyYappTimer = 0;
let lennyTalkNext = 0;

let lennyTalkCycle = 0;

let lennySteaming = 0;
let lennyTalking = false;

let lennyTalkRotationPositions = [-3,-2,0,2,3];

const backgroundBackScreen = new Image(window.innerWidth, window.innerHeight);
backgroundBackScreen.src = require('./images/court.webp');

const lecternImg = new Image(lecternWidth, lecternHeight);
lecternImg.src = require('./images/lectern.webp');

const lennyImg = new Image(lennyWidth, lennyHeight);
lennyImg.src = require('./images/lennyNormalHead.webp');
const lennyYappOneImg = new Image(lennyWidth, lennyHeight);
lennyYappOneImg.src = require('./images/lennyYapp1Head.webp');
const lennyYappTwoImg = new Image(lennyWidth, lennyHeight);
lennyYappTwoImg.src = require('./images/lennyYapp2Head.webp');

const lennyBodyImg = new Image(lennyWidth, lennyHeight);
lennyBodyImg.src = require('./images/lennyBody.webp');

const lennyArmImg = new Image(lennyWidth, lennyHeight);
lennyArmImg.src = require('./images/lennyArm1.webp');

const lennyRedImg = new Image(lennyWidth, lennyHeight);
lennyRedImg.src = require('./images/lennyRed.webp');
const lennyRedYapOneImg = new Image(lennyWidth, lennyHeight);
lennyRedYapOneImg.src = require('./images/lennyRedYap1.webp');
const lennyRedYapTwoImg = new Image(lennyWidth, lennyHeight);
lennyRedYapTwoImg.src = require('./images/lennyRedYap2.webp');

const boothTopImg = new Image(boothWidth, boothHeight);
boothTopImg.src = require('./images/boothTop.webp');

const boothBotImg = new Image(boothWidth, boothHeight);
boothBotImg.src = require('./images/boothBot.webp');

const boothShadowImg = new Image(boothWidth, boothHeight);
boothShadowImg.src = require('./images/boothShadow.webp');

const kkonaImg = new Image(emoteSize, emoteSize);
kkonaImg.src = require('./images/kkona.webp');

const kkonawImg = new Image(emoteSize, emoteSize);
kkonawImg.src = require('./images/kkonaw.png'); //error'd converting to a webp and its a small one anyways

const ballotImg = new Image(ballotWidth, ballotHeight);
ballotImg.src = require('./images/ballot.webp');

const megaImg = new Image(megaWidth, megaHeight);
megaImg.src = require('./images/mega.webp');

const steamImg = new Image(steamW, steamH);
steamImg.src = require('./images/steam.webp');

let lennyCycle = [lennyImg, lennyYappOneImg, lennyYappTwoImg]
let lennyRedCycle = [lennyRedImg, lennyRedYapOneImg, lennyRedYapTwoImg]

let steamDots = [];
const dotList = [];

dotList.push({
	fileName: require("./images/dots_hawk.webp"),
	emotePrefix: "NOPENOPENOPENOPENOPENOPE",
	emoteDirect: ["born2RUN", "CLICK", "moon2LENNY", "moon2HUH", "LLenny", "Kissabrother", "POOLICE", "lennyBASS", "MAHALO", "lennyWalk", "CODE1", "REFRACTING", "WeeWoo", "IMVCB", "KKenny"]
});

dotList.push({
	fileName: require("./images/dots_toretti.webp"),
	emotePrefix: "mantis15",
	emoteDirect: ["moon21", "moon22", "moon23", "moon24"]
});

dotList.push({
	fileName: require("./images/dots_malton.webp"),
	emotePrefix: "generalemu",
	emoteDirect: ["JOHNSOULS"]
});

dotList.push({
	fileName: require("./images/dots_martell.webp"),
	emotePrefix: "hob6",
	emoteDirect: ["moon2SPIN", "borpaSpin", "Borpa"]
});

dotList.push({
	fileName: require("./images/dots_faily.webp"),
	emotePrefix: "aaron",
	emoteDirect: []
});

dotList.push({
	fileName: require("./images/dots_baas.webp"),
	emotePrefix: "saab",
	emoteDirect: []
});

dotList.push({
	fileName: require("./images/dots_knight.webp"),
	emotePrefix: "mehdi",
	emoteDirect: []
});

dotList.push({
	fileName: require("./images/dots_carter.webp"),
	emotePrefix: "afric",
	emoteDirect: []
});

dotList.push({
	fileName: require("./images/dots_tinker.webp"),
	emotePrefix: "tink",
	emoteDirect: []
});

dotList.push({
	fileName: require("./images/dots_reducer.webp"),
	emotePrefix: "brea",
	emoteDirect: []
});

dotList.push({
	fileName: require("./images/dots_vale.webp"),
	emotePrefix: "cath4",
	emoteDirect: []
});

dotList.push({
	fileName: require("./images/dots_kennedy.webp"),
	emotePrefix: "devo",
	emoteDirect: []
});

dotList.push({
	fileName: require("./images/dots_sawyer.webp"),
	emotePrefix: "sel",
	emoteDirect: []
});

for (let i = 0; i < dotList.length; i++)
{
    const ballDot = new Image(ballotWidth, ballotHeight);
	ballDot.src = dotList[i].fileName;
	dotList[i].img = ballDot;
	dotList[i].life = 0;
	dotList[i].lifeBuffer = 0;
}

function approachNumber(num, goal, speed)
{
	if(num > goal - (speed * 2.1) && num < goal + (speed * 2.1))
	{
		return goal
	}
	else if(num < goal)
	{
		num+=speed;
	}
	else if(num > goal)
	{
		num-=speed;
	}
	return num
}

function drawEmote(element)
{
	let emoteSizeToUse = emoteSize;
	let varianceToUse = element.variance;
	
	element.position.x = element.position.x + element.velocity;
	var newY = element.parabolaModifiers.parA * Math.pow(element.position.x, 2) + element.parabolaModifiers.parB * element.position.x + element.parabolaModifiers.parC;
	
	var isPastBooth = (element.position.x > boothX + 450) ? true : false;
	
	if(isPastBooth)
	{
		element.position.y = fullHeight + 113 - newY
	}
	else
	{
		element.position.y = newY
	}

	for (let i = 0; i < element.emotes.length; i++) {
		const emote = element.emotes[i];
		
		var imageToUse = emote.material.canvas;
		if(isPastBooth)
		{
			if(element.emotes[i].name.toLowerCase() == "kkona")
			{
				imageToUse = kkonawImg
			}
			else if(element.emotes[i].name.toLowerCase() == "kkool")
			{
				imageToUse = imageToUse;
			}
			else
			{
				imageToUse = kkonaImg
			}
			
			if(!element.firstVote)
			{
				element.firstVote = true;
				const color = fac.getColor(emote.material.canvas);
				
				if(color.value[0] * 0.7 > color.value[1] && color.value[0] * 0.7 > color.value[2] && redFaceBuffer < 50 * 4 && redFace < 50 * 4)
				{
					redFaceBuffer += 5
					if(lennyTalk > 1)
					{
						redFaceBuffer += 5
					}
				}
				
				for (let k = 0; k < dotList.length; k++)
				{
					if(dotList[k].lifeBuffer > 30 * 4 || dotList[i].life > 30 * 4)
					{
						continue;
					}
					
					if(element.emotes[i].name.startsWith(dotList[k].emotePrefix))
					{
						dotList[k].lifeBuffer += 25
					}
					
					for (let j = 0; j < dotList[k].emoteDirect.length; j++)
					{
						if(dotList[k].emoteDirect[j].toLowerCase() == element.emotes[i].name.toLowerCase())
						{
							if(dotList[k].emotePrefix == "NOPENOPENOPENOPENOPENOPE")
							{
								dotList[k].lifeBuffer += 20
							}
							else if(dotList[k].emotePrefix == "hob6")
							{
								dotList[k].lifeBuffer += 10
							}
							else
							{
								dotList[k].lifeBuffer += 20
							}
						}
					}
				}
			}
		}
		
		ctx.drawImage(imageToUse,
			element.position.x - (emoteSizeToUse / 2),
			(element.position.y) - emoteSizeToUse / 2,
			emoteSizeToUse,
			emoteSizeToUse
		);
	}
	
	if(element.position.y < -100)
	{
		return true
	}
	
	return false
}

function makeRandomNear(num, variance)
{
	return (num - variance/2) + Math.random() * variance
}

const getSteamSpawnPosition = () => {
	
	let x = 240
	let y = 57
	
	let xSpeed = makeRandomNear(2, 1)
	let ySpeed = makeRandomNear(0.7, 0.5)
	
	if(Math.random() * 100 < 50)
	{
		x = 120;
		xSpeed *= -1;
	}

	return {x, y, xSpeed, ySpeed};
}

function drawSteamDot(element)
{
	element.position.x += element.position.xSpeed;
	
	element.position.y -= element.position.ySpeed;
	
	if(element.life < 75)
	{
		element.position.ySpeed = element.position.ySpeed * 1.02
		element.position.xSpeed = element.position.xSpeed * 0.97
	}
	
	if(element.life < 25)
	{
		ctx.globalAlpha = (element.life * 4) / 100
	}
	
	ctx.drawImage(steamImg, element.position.x, element.position.y, element.size, element.size);
	
	ctx.globalAlpha = 1
	
	element.life -= 1.5;
	
	if(element.life < 0)
	{
		return true
	}
	return false
}

window.addEventListener('DOMContentLoaded', () => {
	function resize() {
		canvas.width = window.innerWidth;
		canvas.height = window.innerHeight;
		fullWidth = window.innerWidth;
		fullHeight = window.innerHeight;
	}
	function init() {
		window.addEventListener('resize', resize)
		document.body.appendChild(canvas);
	}

	function draw() {
		requestAnimationFrame(draw);
		
		ctx.drawImage(backgroundBackScreen, 0, 0, fullWidth, fullHeight);

		let thisFrame = Date.now();
		
		//begin handling lenny + his movement
		ctx.save();
		ctx.translate(lennyX + lennyWidth/2, lennyY + lennyHeight/2);
		
		lennyTalkRotation = approachNumber(lennyTalkRotation, lennyTalkRotationBuffer, 0.01)
		if(Math.abs(lennyArmRotatioBuffer - lennyArmRotation) > 0.15)
		{
			lennyArmRotation = approachNumber(lennyArmRotation, lennyArmRotatioBuffer, 0.077)
		}
		else
		{
			lennyArmRotation = approachNumber(lennyArmRotation, lennyArmRotatioBuffer, 0.0033)
		}
		
		ctx.rotate(lennyTalkRotation)
		
		if(lennyTalk > 0)
		{
			if(thisFrame - lennyTalkTimer > 300)
			{
				lennyTalkTimer = thisFrame;
				lennyTalkRotationBuffer = lennyTalkRotationPositions[Math.floor(Math.random() * lennyTalkRotationPositions.length)] * Math.PI / 180
				lennyArmRotatioBuffer = (130 * Math.PI / 180) + lennyTalkRotationBuffer * -1
			}
			if(thisFrame - lennyYappTimer > 111)
			{
				lennyYappTimer = thisFrame;
				lennyTalkCycle = Math.floor(Math.random() * 3)
			}
			
			lennyTalkAdjust = approachNumber(lennyTalkAdjust, 15, 3)
			if(redFace < 1)
			{
				lennyTalk -= 0.5;
			}
			if(lennyTalk < 0)
			{
				lennyTalkTimer = thisFrame;
				lennyArmRotatioBuffer = 0;
				lennyTalkNext = Math.random() * 1000 * 15 + (1000 * 10)
			}
		}
		else if(lennyTalkAdjust == 0 && redFace > 50)
		{
			lennyTalk += Math.random() * 50 + 50;
			lennySteaming = 100;
			lennyTalking = true
			redFace = 100;
		}
		else
		{
			lennyTalkAdjust = approachNumber(lennyTalkAdjust, 0, 1)
			lennyTalkRotationBuffer = 0
			lennyTalkCycle = 0
			lennyTalking = false
		}
		
		ctx.translate(-lennyWidth/2, -lennyHeight/2);
		
		if(lennyTalking)
		{
			redFace = redFace * 0.99;
		}
		else if(lennyTalk < 1)
		{
			redFace += 0.0225
		}
		
		if(lennySteaming > 0)
		{
			lennySteaming -= 1;
			
			if(Math.random() * 100 < 50)
			{
				const newSteam = {
					position: getSteamSpawnPosition(),
					life: 100,
					size: (0.75 + (Math.random() * 0.5)) * steamW
				};
				
				steamDots.push(newSteam);
			}
		}
		
		for (let index = steamDots.length - 1; index >= 0; index--) {
			const element = steamDots[index]	
			
			if(drawSteamDot(element))
			{
				steamDots.splice(index, 1);
			}
		}
		
		ctx.drawImage(lennyCycle[lennyTalkCycle], 0, 0 - lennyTalkAdjust, lennyWidth, lennyHeight);
		ctx.drawImage(lennyBodyImg, 0, 0 - lennyTalkAdjust, lennyWidth, lennyHeight);
		
		if(redFaceBuffer > 0)
		{
			redFace += 0.75;
			redFaceBuffer -= 0.75;
		}
		if(redFace > 0)
		{
			ctx.globalAlpha = redFace < 100 ? redFace/100 : 1;
			
			ctx.drawImage(lennyRedCycle[0], 0, 0 - lennyTalkAdjust, lennyWidth, lennyHeight);
			ctx.drawImage(lennyRedCycle[lennyTalkCycle], 0, 0 - lennyTalkAdjust, lennyWidth, lennyHeight);
			
			ctx.globalAlpha = 1
			//redFace -= 0.5
		}
		
		if(lennyTalkAdjust > 1)
		{
			//dont ask. just dont. poopin magic numbers out my ass
			ctx.save();  
			
			ctx.translate(335, 358.5);
			ctx.rotate(lennyArmRotation)
			ctx.translate(-335, -358.5);
			
			ctx.rotate(235 * Math.PI / 180)
			ctx.drawImage(megaImg, -860, -200, megaWidth, megaHeight);
			ctx.rotate(235 * Math.PI / 180 * -1)
			ctx.drawImage(lennyArmImg, 0 - 155, 0 - 250, lennyWidth * 1.3, lennyHeight * 1.3);
			
			ctx.restore();
		}
		else
		{
			ctx.drawImage(lennyArmImg, 0, 0 - lennyTalkAdjust, lennyWidth, lennyHeight);
		}
		
		ctx.restore();
		//end lenny handling		
		
		ctx.drawImage(lecternImg, lecternX, lennyY + 275, lecternWidth, lecternHeight);
		
		ctx.drawImage(boothShadowImg, 135, 270, fullWidth * 0.79, fullHeight * 0.79);
		
		ctx.drawImage(boothBotImg, boothX, boothY, boothWidth, boothHeight);
		
		//begin ballot drawing + dots
		ctx.save();
		
		ctx.translate(halfx / 2, halfy + 150);
		ctx.rotate(25 * Math.PI / 180)
		
		ctx.drawImage(ballotImg, ballotX, ballotY, ballotWidth, ballotHeight);
		
		for (let i = 0; i < dotList.length; i++)
		{
			if(dotList[i].lifeBuffer > 0)
			{
				dotList[i].life += 0.5;
				dotList[i].lifeBuffer -= 0.5;
			}
			if(dotList[i].life > 0)
			{
				ctx.globalAlpha = dotList[i].life < 100 ? dotList[i].life/100 : 1;
				
				ctx.drawImage(dotList[i].img, ballotX, ballotY, ballotWidth, ballotHeight);
				
				ctx.globalAlpha = 1
				dotList[i].life -= 0.05
			}
		}
		
		ctx.restore();
		//end ballot drawing + dots
		
		for (let index = pendingEmoteArray.length - 1; index >= 0; index--) {
			const element = pendingEmoteArray[index]	
			
			if(drawEmote(element))
			{
				pendingEmoteArray.splice(index, 1);
			}
		}
		
		ctx.drawImage(boothTopImg, boothX, boothY, boothWidth, boothHeight);		
	}

	resize();

	init();
	draw();
})

//calc_parabola_vertex(startX, startY, halfX, halfY, endX, endY);
//formula: yLocation = parA * Math.pow(xLocation, 2) + parB * xLocation + parC
function calc_parabola_vertex(x1, y1, x2, y2, x3, y3)
{
	let denom = (x1-x2) * (x1-x3) * (x2-x3);
	var parA = (x3 * (y2-y1) + x2 * (y1-y3) + x1 * (y3-y2)) / denom;
	var parB = (x3*x3 * (y1-y2) + x2*x2 * (y3-y1) + x1*x1 * (y2-y3)) / denom;
	var parC = (x2 * x3 * (x2-x3) * y1+x3 * x1 * (x3-x1) * y2+x1 * x2 * (x1-x2) * y3) / denom;
	
	return {parA, parB, parC};
}
